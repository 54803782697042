(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/event-by-product-list-widget/assets/javascripts/extract-event-items.js') >= 0) return;  svs.modules.push('/components/sport/event-by-product-list-widget/assets/javascripts/extract-event-items.js');
"use strict";



const _getAllServerImports = () => {
  const path = require('path');
  const trinidad = require('trinidad-core').core;
  const trinidadRequire = trinidad.components.require;
  const localRequire = file => require(path.join(__dirname, file));
  return Object.freeze({
    getLogger: trinidad.logger,
    format: trinidadRequire('utils', 'format').api,
    formatDate: trinidadRequire('lb-utils', 'helper-formatdate').api.formatDate,
    urlMapping: trinidad.urlMapping,
    urlHelperMockable: trinidadRequire('lb-utils', 'helper-get-game-url').api,
    products: trinidadRequire('utils', 'products'),
    getRacingClassName: trinidadRequire('utils', 'racing').getRacingClassName,
    brandMapping: localRequire('../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6'),
    racingConstants: localRequire('../../../../racing/common/assets/javascripts/constants.es6'),
    racingNavigation: localRequire('../../../../racing/race-navigation/assets/javascripts/location.es6'),
    racingServiceParse: localRequire('../../../../racing/race-service/assets/javascripts/utils/parse.es6')
  });
};
const _getAllClientImports = svs => Object.freeze({
  getLogger: svs.core.log.getLogger,
  format: svs.utils.format,
  formatDate: svs.components.lbUtils.helpers.formatDate.formatDate,
  urlMapping: svs.core.urlMapping,
  urlHelperMockable: svs.components.lbUtils,
  products: svs.utils.products,
  brandMapping: svs.components.lbUtils.brandMapping.fn,
  racingConstants: svs.racing.common.constants,
  racingNavigation: svs.racing.navigation.location,
  racingServiceParse: svs.racing.raceService.utils.parse,
  getRacingClassName: svs.utils.racing.getRacingClassName
});
const {
  getLogger,
  format,
  formatDate,
  urlHelperMockable,
  products: {
    getProductClassName,
    productIds
  },
  brandMapping,
  racingConstants,
  racingServiceParse,
  getRacingClassName
} = svs.isServer ? _getAllServerImports() : _getAllClientImports(svs);
const logger = getLogger('widget:event-by-product-list');
const PRODUCT_IDS_WITH_JACKPOT = [productIds.STRYKTIPSET, productIds.EUROPATIPSET, productIds.MALTIPSET];

function extractEventItems(_ref) {
  let {
    target,
    req,
    response,
    jackpotsResponse,
    cmsConfig,
    areaName
  } = _ref;
  switch (target) {
    case 'draws':
      {
        const bestDrawsByProduct = _getBestDrawsByProduct(response);
        const eventItems = Object.values(bestDrawsByProduct).map(draw => _getRelevantDrawData({
          draw,
          jackpotsResponse,
          cmsConfig,
          areaName
        }));
        return eventItems;
      }
    case 'racing':
      {
        return _extractRacing({
          response,
          req,
          jackpotsResponse,
          cmsConfig,
          areaName
        });
      }
    default:
      logger.info("Error in extractEventItems(): Invalid target \"".concat(target, "\""));
      return [];
  }
}

function _getBestDrawsByProduct(drawsResponse) {
  if (!drawsResponse) {
    return {};
  }
  const bestDrawsByProduct = {};
  drawsResponse.result.map(item => item.payload.draw).forEach(currDraw => {
    const productName = getProductClassName(currDraw.productId);
    const prevDraw = bestDrawsByProduct[productName];
    if (prevDraw) {
      const prevCloseTS = Date.parse(prevDraw.regCloseTime);
      const currCloseTS = Date.parse(currDraw.regCloseTime);
      const prevNetSale = parseInt(prevDraw.currentNetSale || prevDraw.currentNetSales, 10);
      const currNetSale = parseInt(currDraw.currentNetSale || currDraw.currentNetSales, 10);
      if (prevCloseTS < currCloseTS || prevCloseTS === currCloseTS && prevNetSale > currNetSale) {
        return;
      }
    }
    bestDrawsByProduct[productName] = currDraw;
  });
  return bestDrawsByProduct;
}

function _getRelevantDrawData(_ref2) {
  let {
    draw,
    jackpotsResponse,
    cmsConfig,
    areaName
  } = _ref2;
  if (!draw) {
    return null;
  }
  const branding = brandMapping.getProductBrand(draw.productId, null, draw.userProduct);
  const events = draw.drawEvents || draw.events || [];
  const productName = getProductClassName(draw.productId);
  const {
    extraSubTitle
  } = _mapCmsConfig({
    cmsConfig,
    productName
  });
  const {
    subTitle,
    closeTime,
    extraFund,
    hasJackpot,
    sortNetSale
  } = _mapProductSpecificData({
    target: 'draws',
    itemData: draw,
    jackpotsResponse
  });
  const subTitles = [closeTime, extraSubTitle, subTitle].filter(Boolean);
  const threeUniqueEvents = events.filter((item, index) => events.findIndex(item2 => item.match.matchId === item2.match.matchId) === index).filter(item => Boolean(item.match.participants[0] && item.match.participants[1])).slice(0, 3);
  const infoRows = threeUniqueEvents.map(_ref3 => {
    let {
      match: {
        league,
        participants
      }
    } = _ref3;
    let homeName = participants[0].name;
    let awayName = participants[1].name;
    if (homeName.length + awayName.length >= 37) {
      homeName = participants[0].mediumName;
      awayName = participants[1].mediumName;
    }
    return {
      smallText: league.name,
      text: "".concat(homeName, " - ").concat(awayName)
    };
  });

  const item = {
    gameUrl: urlHelperMockable.getGameUrl({
      productId: draw.productId,
      drawNumber: draw.drawNumber
    }),
    className: branding,
    brandLogo: branding,
    isHighFive: false,
    areaName,
    icon: cmsConfig.icon,
    subTitle: subTitles[0] || '',
    extraSubTitle: subTitles[1] || null,
    extraFund,
    hasJackpot,
    infoRows,
    footnote: events.length > 0 ? "".concat(infoRows.length, "/").concat(events.length, " objekt") : null,
    sortNetSale,
    sortTS: Date.parse(draw.regCloseTime)
  };
  return item;
}

function _extractRacing(_ref4) {
  var _response$result$, _meeting$track;
  let {
    response,
    req,
    jackpotsResponse,
    cmsConfig,
    areaName
  } = _ref4;
  const {
    gaits
  } = racingConstants;
  const raceItem = response === null || response === void 0 || (_response$result$ = response.result[0]) === null || _response$result$ === void 0 || (_response$result$ = _response$result$.payload) === null || _response$result$ === void 0 ? void 0 : _response$result$.race;
  if (!raceItem) {
    return [];
  }
  const results = [];
  const {
    extraSubTitle
  } = _mapCmsConfig({
    cmsConfig,
    productName: 'HighFive'
  });
  const {
    subTitle,
    closeTime,
    extraFund,
    hasJackpot,
    sortNetSale
  } = _mapProductSpecificData({
    target: 'racing',
    itemData: raceItem,
    jackpotsResponse
  });
  const subTitles = [closeTime, extraSubTitle, subTitle].filter(Boolean);
  const {
    parseDistance
  } = racingServiceParse;
  const {
    raceNumber,
    raceName,
    distance,
    distanceUnit,
    gait,
    startType,
    meeting
  } = raceItem;
  const distanceText = distance > 1000 ? "".concat(Math.floor(distance / 1000), " ").concat(String(distance % 1000).padStart(3, '0')) : distance;
  const startTypeText = String(startType).toLowerCase() === 'undefined' ? '' : " ".concat(startType);
  const infoRows = [{
    smallText: 'Bana',
    text: meeting !== null && meeting !== void 0 && (_meeting$track = meeting.track) !== null && _meeting$track !== void 0 && _meeting$track.trackName && raceNumber ? "".concat(meeting.track.trackName, " Lopp ").concat(raceNumber) : null
  }, {
    smallText: 'Sport',
    text: gait ? {
      [gaits.GALLOP]: 'Galopp',
      [gaits.TROTTING]: 'Trav'
    }[gait.toLowerCase()] : null
  }, {
    smallText: 'Distans',
    text: distance && distanceUnit ? "".concat(parseDistance(distanceText, distanceUnit)).concat(startTypeText) : null
  }].filter(item => Boolean(item.text));
  const racingClassName = getRacingClassName(productIds.RACING);

  const item = {
    gameUrl: urlHelperMockable.getRacingGameUrl({
      race: raceItem,
      userProduct: 'Rx5io'
    }),
    className: racingClassName,
    brandLogo: "".concat(racingClassName, "-secondary"),
    isHighFive: true,
    areaName,
    icon: cmsConfig.icon,
    subTitle: subTitles[0],
    extraSubTitle: subTitles[1],
    extraFund,
    hasJackpot,
    infoRows,
    footnote: raceName && String(raceName).toLowerCase() !== 'undefined' ? raceName : null,
    sortNetSale,
    sortTS: Date.parse(raceItem.startTimeTentative)
  };
  results.push(item);
  return results;
}

function _mapCmsConfig(_ref5) {
  let {
    cmsConfig,
    productName
  } = _ref5;
  const capitalizedName = "".concat(productName[0].toUpperCase()).concat(productName.slice(1));
  const subtitlePropName = "subtitle".concat(capitalizedName);
  const extraSubTitle = cmsConfig[subtitlePropName] ? cmsConfig[subtitlePropName] : '';
  return {
    extraSubTitle
  };
}

function _mapProductSpecificData(_ref6) {
  let {
    target,
    itemData,
    jackpotsResponse
  } = _ref6;
  const isDraw = target === 'draws';
  const isRacing = target === 'racing';
  if (!isDraw && !isRacing) {
    logger.info("Error in _mapProductSpecificData() of extractEventItems(): Invalid target \"".concat(target, "\""));
    return {};
  }
  const closeTimeField = isDraw ? 'regCloseTime' : 'startTimeTentative';
  const closeTime = "Spelstopp: ".concat(formatDate(itemData[closeTimeField], true, true, true));
  let extraFund = _getJackpotText({
    isDraw,
    isRacing,
    itemData,
    jackpotsResponse
  });
  const hasJackpot = Boolean(extraFund);
  extraFund = extraFund || _getFundText({
    itemData
  }) || _getNetsaleText({
    isDraw,
    isRacing,
    itemData,
    jackpotsResponse
  });
  const subTitle = isDraw ? itemData.description || itemData.eventDescription : null;
  let sortNetSale = 0;
  if (isDraw) {
    sortNetSale = parseFloat(String(itemData.currentNetSale || itemData.currentNetSales || '0').replace(',', '.'));
  }
  if (isRacing) {
    const jackpotData = jackpotsResponse === null || jackpotsResponse === void 0 ? void 0 : jackpotsResponse.turnover;
    if (jackpotData && itemData.raceId === jackpotData.raceId) {
      sortNetSale = parseFloat(String(jackpotData.turnover).replace(',', '.'));
    }
  }
  return {
    closeTime,
    subTitle,
    extraFund,
    hasJackpot,
    sortNetSale
  };
}

function _getJackpotText(_ref7) {
  let {
    isDraw,
    isRacing,
    itemData,
    jackpotsResponse
  } = _ref7;
  const {
    CurrencyInMillions: formatCurrencyInMillions
  } = format;
  const hasJackpotSupport = isDraw ? PRODUCT_IDS_WITH_JACKPOT.includes(itemData.productId) : true;
  if (!hasJackpotSupport) {
    return null;
  }
  let jackpotAmount;
  if (isDraw) {
    var _jackpot$jackpots;
    const jackpot = jackpotsResponse === null || jackpotsResponse === void 0 ? void 0 : jackpotsResponse.jackpots.find(item => item.drawNumber === itemData.drawNumber);
    jackpotAmount = jackpot && ((_jackpot$jackpots = jackpot.jackpots) === null || _jackpot$jackpots === void 0 ? void 0 : _jackpot$jackpots.length) > 0 ? jackpot.jackpots[0].jackpotAmount : 0;
  }
  if (isRacing) {
    const jackpotData = jackpotsResponse === null || jackpotsResponse === void 0 ? void 0 : jackpotsResponse.turnover;
    if (jackpotData) {
      if (itemData.raceId === jackpotData.raceId) {
        jackpotAmount = parseInt(jackpotData.jackpot, 10) > 0 ? jackpotData.jackpot : 0;
      } else {
        logger.info("Warning: Expected turnover-data to match raceId (".concat(jackpotData.raceId, " != ").concat(itemData.raceId, ")"));
      }
    }
  }
  return parseInt(jackpotAmount || 0, 10) > 0 ? "Jackpot: ".concat(formatCurrencyInMillions(jackpotAmount), " milj kr") : null;
}

function _getFundText(_ref8) {
  let {
    itemData
  } = _ref8;
  const {
    Currency: formatCurrency
  } = format;
  if (itemData.fund) {
    const extraMoney = formatCurrency(itemData.fund.extraMoney);
    const rolloverIn = formatCurrency(itemData.fund.rolloverIn);
    if (extraMoney !== '0') {
      return "Extrapengar: ".concat(extraMoney, " kr");
    }
    if (rolloverIn !== '0') {
      return "Rullpott: ".concat(rolloverIn, " kr");
    }
  }
  return null;
}

function _getNetsaleText(_ref9) {
  let {
    isDraw,
    isRacing,
    itemData,
    jackpotsResponse
  } = _ref9;
  const {
    Currency: formatCurrency
  } = format;
  let value;
  if (isDraw) {
    value = itemData.currentNetSale || itemData.currentNetSales;
  }
  if (isRacing) {
    const jackpotData = jackpotsResponse === null || jackpotsResponse === void 0 ? void 0 : jackpotsResponse.turnover;
    if (jackpotData) {
      if (itemData.raceId === jackpotData.raceId) {
        value = jackpotData.turnover;
      } else {
        logger.info("Warning: Expected turnover-data to match raceId (".concat(jackpotData.raceId, " != ").concat(itemData.raceId, ")"));
      }
    }
  }
  return parseInt(value || 0, 10) > 0 ? "Oms\xE4ttning: ".concat(formatCurrency(value), " kr") : null;
}

if (svs.isServer) {
  module.exports = extractEventItems;
} else {
  setGlobal('svs.components.sport.eventByProductListWidget', {
    extractEventItems
  });
}

 })(window);