(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/assets/javascripts/poker-router.js') >= 0) return;  svs.modules.push('/modules/poker-react/assets/javascripts/poker-router.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  BrowserRouter: Router,
  Route,
  Switch
} = ReactRouterDOM;
const {
  useEffect
} = React;
const {
  routeComponents: {
    RouteNews,
    RouteWidget,
    RouteRanking
  },
  components: {
    PokerNavMenu
  }
} = svs.poker_react;
const {
  ModalHidden,
  ModalPortal
} = svs.components.lbUi.modal;
const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
const {
  BrowserStorageConstants,
  MenuTabTypes
} = svs.poker_react.components.common.constants;
const {
  browser: BrowserStorage
} = svs.components.Storage;
const {
  launchWebClient
} = svs.poker_react.components.common.helpers;
const getRouteComponent = tabType => {
  switch (tabType) {
    case MenuTabTypes.News:
      return RouteNews;
    case MenuTabTypes.Ranking:
      return RouteRanking;
    default:
      return RouteWidget;
  }
};
const PokerRouter = () => {
  const routes = svs.poker_react.data.tabsConfig;

  useEffect(() => {
    if (!isLoggedIn) {
      BrowserStorage.remove(BrowserStorageConstants.OpenWebClientKey, BrowserStorageConstants.Domain);
      return;
    }
    BrowserStorage.get(BrowserStorageConstants.OpenWebClientKey, BrowserStorageConstants.Domain, (data, err) => {
      if (!data || err) {
        return;
      }
      if (data.openWebClient) {
        launchWebClient(_objectSpread(_objectSpread({}, data.params), {}, {
          shouldTrackEvent: false
        }));
        BrowserStorage.remove(BrowserStorageConstants.OpenWebClientKey, BrowserStorageConstants.Domain);
      }
    });
  }, []);
  const activeRoutes = routes.filter(route => route.active);
  return React.createElement(Router, {
    basename: svs.core.data.baseUrl
  }, React.createElement(ModalPortal, null, React.createElement(ModalHidden, null, React.createElement("div", {
    className: "poker-react-bg-layer"
  }, React.createElement(PokerNavMenu, {
    routes: activeRoutes
  }), React.createElement(Switch, null, React.createElement(Route, {
    component: RouteNews,
    exact: true,
    path: "/"
  }), activeRoutes.map(route => React.createElement(Route, {
    key: route.path,
    path: "/".concat(route.path),
    render: () => {
      const Component = getRouteComponent(route.tabType);
      return React.createElement(Component, {
        widgetAreaIndex: route.widgetArea
      });
    }
  })))), React.createElement("div", {
    className: "poker-react-bg-spotlight"
  }))));
};
setGlobal('svs.poker_react.components.PokerRouter', PokerRouter);

 })(window);