(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/upcoming-events.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/upcoming-events.js');
"use strict";


const {
  useEffect,
  useReducer,
  useRef,
  useState
} = React;
const {
  ReactButton: Button
} = svs.ui;
const {
  common: {
    constants: {
      TournamentFilters,
      GameTypes,
      BuyinFiltersSEK,
      SatelliteFilters,
      TournamentFetchMoreCount
    },
    helpers: {
      launchWebClient
    }
  }
} = svs.poker_react.components;
const {
  FilterModal,
  TournamentListItem,
  HighlightedCard,
  BuyinFilter,
  GameTypeFilter,
  TournamentTypeFilter,
  Skeleton,
  useGetTournamentEvents,
  SatelliteFilter
} = svs.poker_react.components.upcomingEvents;
const {
  pokerSMVisible
} = svs.poker_react.data.upcomingEventsData;
const {
  reducer,
  actionTypes
} = svs.poker_react.components.upcomingEvents.filterReducer;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const ANIMATION_DURATION = 1;
const ANIMATION_EASING = 'sine.out';
const LIST_ITEM_HEIGHT = 90;
const UpcomingEvents = () => {
  const [activeFilters, dispatch] = useReducer(reducer, {
    tournamentType: pokerSMVisible ? TournamentFilters[2].id : TournamentFilters[0].id,
    gameType: GameTypes[0].id,
    buyin: BuyinFiltersSEK[0],
    satellite: SatelliteFilters[0].value
  });
  const isPokerSM = activeFilters.tournamentType === 'poker-sm';
  const [offset, setOffset] = useState(0);
  const tournamentContainer = useRef();
  const upcomingEventsContainer = useRef();
  const isWideDevice = useMediaQuery(breakpoints.up(BREAKPOINT_MD));
  const {
    tournaments,
    moreItems,
    shouldShowSkeleton,
    error,
    isFetchingMore
  } = useGetTournamentEvents(activeFilters, offset);
  useEffect(() => {
    const container = tournamentContainer.current;
    if (container && offset > 0) {
      gsap.to(container, {
        maxHeight: container.scrollHeight + LIST_ITEM_HEIGHT * TournamentFetchMoreCount,
        duration: ANIMATION_DURATION,
        ease: ANIMATION_EASING
      });
    } else if (offset === 0) {
      container.removeAttribute('style');
    }
  }, [offset, isWideDevice]);
  const setFilter = (actionType, payload) => {
    setOffset(0);
    dispatch({
      type: actionType,
      payload
    });
  };
  const goToTournament = (tournamentId, tournamentName) => {
    launchWebClient({
      tournamentId,
      tournamentName
    });
  };
  const renderFilters = () => {
    if (isPokerSM) {
      return React.createElement("div", {
        className: "satellite-filter-container"
      }, React.createElement(SatelliteFilter, {
        activeSatelliteFilter: activeFilters.satellite,
        setActiveSatelliteFilter: filter => setFilter(actionTypes.SET_SATELLITE_FILTER, filter)
      }));
    }
    return React.createElement("div", {
      className: "dropdown-container"
    }, React.createElement(GameTypeFilter, {
      activeGameTypeFilter: activeFilters.gameType,
      setActiveGameTypeFilter: filter => setFilter(actionTypes.SET_GAME_TYPE_FILTER, filter)
    }), React.createElement(BuyinFilter, {
      activeBuyinFilter: activeFilters.buyin,
      isNetwork: activeFilters.tournamentType === 'network',
      setActiveBuyinFilter: filter => setFilter(actionTypes.SET_BUY_IN_FILTER, filter)
    }));
  };
  const renderPokerEventsList = pokerEvents => {
    if (shouldShowSkeleton || !pokerEvents) {
      return React.createElement(Skeleton, null);
    }
    if (error) {
      return React.createElement("div", {
        className: "f-content error-message"
      }, React.createElement("h3", null, "N\xE5got gick fel"));
    }
    if (pokerEvents.length === 0) {
      return React.createElement("div", {
        className: "f-content error-message"
      }, React.createElement("h3", null, "Inga events hittades!"));
    }
    return pokerEvents.map((_ref, i) => {
      let {
        tournamentId,
        registrationEndTime,
        name,
        buyIn,
        registrationCount,
        startTime,
        isLateReg,
        currency
      } = _ref;
      if (pokerEvents.length === 1) {
        return React.createElement(TournamentListItem, {
          buyIn: buyIn,
          currency: currency,
          isLateReg: isLateReg,
          key: tournamentId,
          name: name,
          onClick: goToTournament,
          registrationCount: registrationCount,
          registrationEndTime: registrationEndTime,
          startTime: startTime,
          tournamentId: tournamentId
        });
      }
      if (i === 0) {
        return React.createElement(HighlightedCard, {
          buyIn: buyIn,
          currency: currency,
          isLateReg: isLateReg,
          key: tournamentId,
          name: name,
          onRegistrationClick: goToTournament,
          registrationCount: registrationCount,
          registrationEndTime: registrationEndTime,
          startTime: startTime,
          tournamentId: tournamentId
        });
      }
      return React.createElement(TournamentListItem, {
        buyIn: buyIn,
        currency: currency,
        isLateReg: isLateReg,
        key: tournamentId,
        name: name,
        onClick: goToTournament,
        registrationCount: registrationCount,
        registrationEndTime: registrationEndTime,
        startTime: startTime,
        tournamentId: tournamentId
      });
    });
  };
  return React.createElement("div", {
    className: "upcoming-events-container f-content",
    ref: upcomingEventsContainer
  }, React.createElement("h3", {
    className: "h2 center-text"
  }, "Turneringar"), isWideDevice && React.createElement(TournamentTypeFilter, {
    activeTournamentFilter: activeFilters.tournamentType,
    setTournamentFilter: filter => setFilter(actionTypes.SET_TOURNAMENT_FILTER, filter)
  }), !isWideDevice && React.createElement(FilterModal, {
    activeFilters: activeFilters,
    dispatch: dispatch,
    isPokerSM: isPokerSM
  }), renderFilters(), React.createElement("hr", {
    className: "divider"
  }), React.createElement("div", {
    className: "tournament-container",
    ref: tournamentContainer
  }, renderPokerEventsList(tournaments)), moreItems && React.createElement(Button, {
    className: "btn-poker btn-show-all",
    disabled: isFetchingMore,
    onClick: () => setOffset(offset + 5)
  }, "Visa fler"));
};
setGlobal('svs.poker_react.components.upcomingEvents.UpcomingEvents', UpcomingEvents);

 })(window);