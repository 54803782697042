(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/assets/javascripts/route-components/route-news.js') >= 0) return;  svs.modules.push('/modules/poker-react/assets/javascripts/route-components/route-news.js');
"use strict";


const WidgetArea = svs.components.lbUtils.widgetArea.WidgetArea;
const {
  StaticWidgetArea
} = svs.poker_react.components.common.constants;
const {
  UpcomingEvents
} = svs.poker_react.components.upcomingEvents;
const {
  TournamentRegistrationForm
} = svs.poker_react.components.tournamentRegistrationForm;
const {
  ReactAccordion: {
    Accordion,
    AccordionItem,
    AccordionSummary,
    AccordionDetails
  }
} = svs.ui;
const {
  allDevices
} = svs.poker_react.data.clientDownloadLinks;
const clientDownloadHelp = svs.poker_react.data.clientDownloadHelp;
const {
  handleDownloadClick
} = svs.poker_react.components.common.helpers;
const HeroBanner = svs.poker_react.components.HeroBanner;
const RouteNews = () => React.createElement(React.Fragment, null, React.createElement(HeroBanner, null), React.createElement("div", {
  className: "poker-react-content"
}, React.createElement(UpcomingEvents, null), React.createElement("div", {
  className: "tournament-section"
}, React.createElement("h3", {
  className: "center-text h2"
}, "Boka turnering"), React.createElement(TournamentRegistrationForm, null)), clientDownloadHelp && React.createElement(Accordion, {
  className: "client-links-accordion"
}, React.createElement(AccordionItem, {
  className: "client-links-accordion-details"
}, React.createElement(AccordionSummary, null, React.createElement("h2", {
  className: "f-content h2"
}, clientDownloadHelp.header)), React.createElement(AccordionDetails, null, React.createElement("p", null, clientDownloadHelp.descriptionText), React.createElement("p", null, "H\xE4r kan du v\xE4lja att ladda ner den klient som just du beh\xF6ver"), React.createElement("ul", null, allDevices.map(_ref => {
  let {
    device,
    url,
    text
  } = _ref;
  return React.createElement("li", {
    key: device
  }, React.createElement("a", {
    className: "f-bold",
    onClick: () => handleDownloadClick(device, url)
  }, text));
}))))), React.createElement(WidgetArea, {
  widgetArea: StaticWidgetArea.SEO_Optimization
})));
setGlobal('svs.poker_react.routeComponents.RouteNews', RouteNews);

 })(window);