(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-value-editor/assets/javascripts/use-value-editor.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-value-editor/assets/javascripts/use-value-editor.js');
"use strict";

const _excluded = ["label", "onChange", "size", "value"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }

const {
  useState
} = React;
const {
  ValueEditor
} = svs.components.lbUtils.useValueEditor;
const {
  clx
} = svs.components.lbUtils;
const SelectorType = Object.freeze({
  RADIO: 'enum/radio',
  CHECKBOX: 'enum/checkbox',
  SELECT: 'enum/select'
});
const ValueEditorType = Object.freeze({
  BOOLEAN: 'boolean',
  CHECKBOX: 'enum/checkbox',
  COLOR: 'color',
  ENUM: 'enum',
  NUMBER: 'number',
  RADIO: 'enum/radio',
  SELECT: 'enum/select',
  SLIDER: 'slider',
  TEXT: 'text',
  TEXTAREA: 'textarea'
});
const greyscales = [{
  label: 'grey-800',
  value: '#252526'
}, {
  label: 'grey-700',
  value: '#323233'
}, {
  label: 'grey-600',
  value: '#4b4b4d'
}, {
  label: 'grey-500',
  value: '#7d7d80'
}, {
  label: 'grey-400',
  value: '#afafb3'
}, {
  label: 'grey-330',
  value: '#d4d4d9'
}, {
  label: 'grey-300',
  value: '#e1e1e5'
}, {
  label: 'grey-200',
  value: '#ededf2'
}, {
  label: 'grey-100',
  value: '#fafaff'
}];
const basecolors = [{
  label: 'red',
  value: '#ed0000'
}, {
  label: 'red-corporate-brand',
  value: '#ff0000'
}, {
  label: 'red-100',
  value: '#ffeceb'
}, {
  label: 'black',
  value: '#000000'
}, {
  label: 'white',
  value: '#ffffff'
}, {
  label: 'yellow',
  value: '#ffbb00'
}, {
  label: 'green',
  value: '#0bad20'
}, {
  label: 'blue',
  value: '#256FEA'
}, {
  label: 'navy',
  value: '#001932'
}, {
  label: 'orange',
  value: '#ff5500'
}];
const stryktipset = [{
  label: 'stryktipset',
  value: '#00427a'
}, {
  label: 'stryktipset-800',
  value: '#e60000'
}, {
  label: 'stryktipset-700',
  value: '#052038'
}, {
  label: 'stryktipset-600',
  value: '#273c4d'
}, {
  label: 'stryktipset-500',
  value: '#072f52'
}, {
  label: 'stryktipset-400',
  value: '#466c8b'
}, {
  label: 'stryktipset-300',
  value: '#0063b7'
}, {
  label: 'stryktipset-200',
  value: '#0063b7'
}, {
  label: 'stryktipset-100',
  value: '#0073ff'
}, {
  label: 'stryktipset-banner',
  value: '#4F6CA9'
}];
const useValueEditor = config => {
  var _config$theme, _config$theme2;
  const {
    ControlGroup,
    Inputv2: Input,
    Select,
    Option,
    Checkbox,
    Radio
  } = svs.ui.reactForm;
  const INPUT_SIZE = (config === null || config === void 0 || (_config$theme = config.theme) === null || _config$theme === void 0 ? void 0 : _config$theme.input_size) || '100';
  const INVERTED_THEME = (config === null || config === void 0 || (_config$theme2 = config.theme) === null || _config$theme2 === void 0 ? void 0 : _config$theme2.invert) || false;

  const getSelectorType = enumObj => {
    if (enumObj.options.length > 3) {
      return SelectorType.SELECT;
    }
    if (enumObj.multiselect) {
      return SelectorType.CHECKBOX;
    }
    return SelectorType.RADIO;
  };
  const checkOptions = function (option, options) {
    let multiselect = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    if (multiselect) {
      option.checked = String(option.checked) !== 'true';
    } else {
      options.forEach(el => {
        const v = el === option;
        el.checked = v;
      });
    }
  };

  const getInputForEnum = (type, key, value, props) => {
    switch (type) {
      case SelectorType.RADIO:
        return React.createElement(React.Fragment, null, value.options.map(option => React.createElement(Radio, {
          className: clx({
            'form-toggle-inverted': INVERTED_THEME
          }),
          defaultChecked: option.selected,
          inline: true,
          key: option.label,
          name: "".concat(key, "[]"),
          onChange: e => {
            e.target.checked = true;
            return props.onChange(e);
          },
          size: INPUT_SIZE,
          value: option.value
        }, option.label)));
      case SelectorType.CHECKBOX:
        return React.createElement(React.Fragment, null, value.options.map(option => React.createElement(Checkbox, {
          checked: option.selected,
          className: clx({
            'form-toggle-inverted': INVERTED_THEME
          }),
          inline: true,
          key: option.label,
          name: "".concat(key, "[]"),
          onChange: e => {
            checkOptions(e.target, [...e.target.closest('.control-group').querySelectorAll('input[type=checkbox]')], value.multiselect);
            return props.onChange(e);
          },
          size: INPUT_SIZE,
          value: "".concat(option.value)
        }, option.label)));
      default:
        {
          const {
              label,
              onChange,
              size,
              value: defaultValue
            } = props,
            rest = _objectWithoutProperties(props, _excluded);
          return React.createElement(Select, _extends({}, rest, {
            onChange: e => {
              e.target.checked = true;
              return props.onChange(e);
            },
            v2: true,
            value: "".concat(defaultValue)
          }), value.options.map(option => React.createElement(Option, {
            key: option.label,
            value: "".concat(option.value)
          }, option.label)));
        }
    }
  };
  const handleFormat = (fmt, value) => {
    switch (fmt) {
      case 'number':
        return Number(value || 0);
      case 'string':
      default:
        return value || '';
    }
  };

  const [state, setState] = useState(() => Object.entries(config).reduce((acc, _ref) => {
    let [key, value] = _ref;
    let _value = value.value;
    if ([ValueEditorType.CHECKBOX, ValueEditorType.ENUM, ValueEditorType.RADIO, ValueEditorType.SELECT].includes(value.type)) {
      var _value$value, _value$value2;
      const selected = (_value$value = value.value) === null || _value$value === void 0 ? void 0 : _value$value.options.find(opt => opt.selected);
      _value = (selected === null || selected === void 0 ? void 0 : selected.value) || ((_value$value2 = value.value) === null || _value$value2 === void 0 ? void 0 : _value$value2.options[0].value);
    }
    return _objectSpread(_objectSpread({}, acc), {}, {
      [key]: _value
    });
  }, {}));

  const inputs = Object.entries(config).map(_ref2 => {
    let [key, value] = _ref2;
    const label = key.split(/(?=[A-Z])/).join(' ');
    const props = {
      label,
      onChange: e => setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
        [key]: handleFormat(value === null || value === void 0 ? void 0 : value.format, e.target.value)
      })),
      size: INPUT_SIZE,
      value: state[key]
    };
    switch (value.type) {
      case ValueEditorType.BOOLEAN:
        return React.createElement(ControlGroup, {
          key: key,
          v2: true
        }, React.createElement(Checkbox, {
          checked: state[key],
          className: clx({
            'form-toggle-inverted': INVERTED_THEME
          }),
          inline: true,
          name: key,
          onChange: () => setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
            [key]: !prev[key]
          })),
          size: INPUT_SIZE
        }, label));
      case ValueEditorType.COLOR:
        return React.createElement(ControlGroup, {
          className: "colorpicker",
          key: key,
          v2: true
        }, React.createElement(Input, _extends({}, props, {
          list: "presets",
          type: "color"
        })), React.createElement("datalist", {
          id: "presets"
        }, [...greyscales, ...basecolors, ...stryktipset].map(_ref3 => {
          let {
            label,
            value
          } = _ref3;
          return React.createElement("option", {
            key: label,
            value: value
          }, value);
        })));
      case ValueEditorType.ENUM:
        return React.createElement(ControlGroup, {
          key: key,
          v2: true
        }, React.createElement("label", null, label), React.createElement("div", {
          style: {
            display: 'flex'
          }
        }, getInputForEnum(getSelectorType(value.value), key, value.value, props)));
      case ValueEditorType.CHECKBOX:
      case ValueEditorType.RADIO:
      case ValueEditorType.SELECT:
        return React.createElement(ControlGroup, {
          key: key,
          v2: true
        }, React.createElement("label", null, label), React.createElement("div", {
          style: {
            display: 'flex'
          }
        }, getInputForEnum(value.type, key, value.value, props)));
      case ValueEditorType.SLIDER:
        return React.createElement(ControlGroup, {
          key: key,
          v2: true
        }, React.createElement(Input, _extends({}, props, {
          className: "value-editor-slider",
          max: value.max || 1,
          min: value.min || 0,
          onChange: e => setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
            [key]: Number(e.target.value)
          })),
          step: value.step || 0.01,
          suffix: React.createElement("input", {
            className: "value-editor-slider-input",
            max: value.max || 1,
            min: value.min || 0,
            onChange: e => setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
              [key]: Number(e.target.value)
            })),
            type: "number",
            value: state[key]
          }),
          type: "range"
        })));
      case ValueEditorType.NUMBER:
        return React.createElement(ControlGroup, {
          key: key,
          v2: true
        }, React.createElement(Input, _extends({}, props, {
          onChange: e => setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
            [key]: Number(e.target.value)
          })),
          type: "number"
        })));
      case ValueEditorType.TEXTAREA:
        return React.createElement(ControlGroup, {
          key: key,
          v2: true
        }, React.createElement(Input, _extends({}, props, {
          multiline: true,
          rows: "4",
          type: "text"
        })));
      case ValueEditorType.TEXT:
      default:
        return React.createElement(ControlGroup, {
          key: key,
          v2: true
        }, React.createElement(Input, _extends({}, props, {
          type: "text"
        })));
    }
  });
  const render = function (inputs) {
    let isPackaged = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return React.createElement(ValueEditor, {
      isInverted: INVERTED_THEME,
      isPackaged: isPackaged
    }, inputs);
  };
  return {
    values: state,
    inputs,
    render
  };
};
setGlobal('svs.components.lbUtils.useValueEditor.useValueEditor', useValueEditor);
setGlobal('svs.components.lbUtils.useValueEditor.ValueEditorType', ValueEditorType);

 })(window);