(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/hero/assets/javascripts/components/hero-loader.js') >= 0) return;  svs.modules.push('/components/lb-ui/hero/assets/javascripts/components/hero-loader.js');

'use strict';

const HeroLoader = () => React.createElement("div", {
  className: "hero-loader"
}, React.createElement("svg", {
  className: "hero-loader-spinner"
}, React.createElement("circle", {
  className: "hero-loader-path"
})), React.createElement("div", {
  className: "hero-loader-left"
}), React.createElement("div", {
  className: "hero-loader-right"
}));
setGlobal('svs.components.lbUi.hero.components.HeroLoader', HeroLoader);

 })(window);