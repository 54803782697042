(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/hero-banner/assets/javascripts/hero-banner.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/hero-banner/assets/javascripts/hero-banner.js');
"use strict";


const {
  ReactButton: Button
} = svs.ui;
const {
  launchWebClient,
  handleDownloadClick
} = svs.poker_react.components.common.helpers;
const {
  detectedDevice
} = svs.poker_react.data.clientDownloadLinks;
const {
  Hero
} = svs.components.lbUi.hero;
const {
  heroImage = {},
  title,
  body
} = svs.poker_react.data.heroData;
const HeroBanner = () => React.createElement(Hero, {
  branding: "poker",
  className: "hero-banner",
  image: "".concat(svs.core.config.data.svsconfig.cdn2).concat(heroImage.url)
}, React.createElement("h2", {
  className: "center-text poker-h2"
}, title), React.createElement("p", {
  className: "hero-body"
}, body), React.createElement("div", {
  className: "btn-container"
}, React.createElement(Button, {
  className: "btn-poker",
  onClick: () => launchWebClient(),
  transparent: true
}, "\xD6ppna pokerklienten"), detectedDevice && detectedDevice.showDevice && React.createElement(Button, {
  className: "btn-poker",
  onClick: () => handleDownloadClick(detectedDevice.device, detectedDevice.url),
  transparent: true
}, detectedDevice.text)));
setGlobal('svs.poker_react.components.HeroBanner', HeroBanner);

 })(window);