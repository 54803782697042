(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/assets/javascripts/route-components/route-ranking.js') >= 0) return;  svs.modules.push('/modules/poker-react/assets/javascripts/route-components/route-ranking.js');
"use strict";


const WidgetArea = svs.components.lbUtils.widgetArea.WidgetArea;
const {
  PoyScoreCalculator,
  PoyWinners,
  poyRankingList: {
    PoyRankingList
  }
} = svs.poker_react.components;
const {
  StaticWidgetArea
} = svs.poker_react.components.common.constants;
const RouteRanking = () => {
  var _svs$poker_react$data, _svs$poker_react$data2;
  const rankingListVisible = (_svs$poker_react$data = svs.poker_react.data.poyCurrentRankingConfig) === null || _svs$poker_react$data === void 0 ? void 0 : _svs$poker_react$data.visible;
  const winnerListVisible = (_svs$poker_react$data2 = svs.poker_react.data.poyWinConfig) === null || _svs$poker_react$data2 === void 0 ? void 0 : _svs$poker_react$data2.listVisible;
  return React.createElement("div", {
    className: "poker-react-content"
  }, rankingListVisible && React.createElement(PoyRankingList, null), winnerListVisible && React.createElement(PoyWinners, null), React.createElement(WidgetArea, {
    widgetArea: StaticWidgetArea.Ranking
  }), React.createElement(PoyScoreCalculator, null));
};
setGlobal('svs.poker_react.routeComponents.RouteRanking', RouteRanking);

 })(window);