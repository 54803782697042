(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-value-editor/assets/javascripts/value-editor.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-value-editor/assets/javascripts/value-editor.js');
"use strict";


const {
  useState
} = React;
const {
  clx
} = svs.components.lbUtils;
const ValueEditor = _ref => {
  let {
    children,
    isInverted,
    isPackaged,
    items
  } = _ref;
  const [expanded, setExpanded] = useState(false);
  const renderEditor = () => React.createElement("div", {
    className: clx('value-editor', {
      inverted: isInverted
    })
  }, items ? items : children);
  if (isPackaged) {
    return React.createElement("div", {
      className: clx('value-editor-menu', {
        expanded
      })
    }, React.createElement("div", {
      className: "value-editor-menu-content"
    }, renderEditor()), React.createElement("button", {
      className: "toggle-value-editor-menu",
      onClick: () => setExpanded(prev => !prev),
      type: "button"
    }));
  }
  return renderEditor();
};
setGlobal('svs.components.lbUtils.useValueEditor.ValueEditor', ValueEditor);

 })(window);