(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/brand-cube/assets/javascripts/brand-cube.js') >= 0) return;  svs.modules.push('/components/lb-ui/brand-cube/assets/javascripts/brand-cube.js');

'use strict';

const {
  useCallback,
  useEffect,
  useRef
} = React;
const {
  clx
} = svs.components.lbUtils;
const {
  getClassName,
  productIds
} = svs.components.lbUtils.sportProducts;
const {
  useMediaQuery,
  features
} = svs.components.lbUtils.useMediaQuery;
const GENERIC_PRODUCT = 11111;


const BrandCube = _ref => {
  let {
    className,
    options = {},
    productId,
    style
  } = _ref;
  const requestRef = useRef();
  const cubeRef = useRef();
  const timeRef = useRef();
  const data = useRef({
    offset: {
      x: 0,
      y: 0,
      z: 0
    },
    fp: 0,
    lp: 0,
    tp: 0,
    bp: 0
  });
  const shouldReduceMotion = useMediaQuery(features.PREFERS_REDUCED_MOTION);
  const speed = shouldReduceMotion ? 0 : (options === null || options === void 0 ? void 0 : options.speed) || 30;
  const product = productId === GENERIC_PRODUCT ? 'generic' : getClassName(productId);
  const update = useCallback(() => {
    const timePassed = Date.now() - timeRef.current;
    if (cubeRef.current && data.current) {
      const cube = cubeRef.current;
      const {
        offset
      } = data.current;
      const delta = performance.now() * speed / 1000 % 360;
      offset.x = Math.cos(delta * Math.PI / 180) / 3;
      offset.y = Math.sin(delta * Math.PI / 180) / 3;
      let rx = -offset.x * 10 - 10;
      let ry = offset.y * 50 + 40;
      let rz = 8;
      switch (productId) {
        case productIds.FULLTRAFF:
        case productIds.MALTIPSET:
          rz = -8;
          break;
        case productIds.BINGO_CLASSIC:
          rx = 7 - offset.x * 20;
          ry = 43 + offset.y * 50;
          rz = 351;
          break;
        default:
      }
      if (options.offset || options.rotation) {
        var _options$offset, _options$rotation, _options$offset2, _options$rotation2, _options$offset3, _options$rotation3;
        rx = ((_options$offset = options.offset) === null || _options$offset === void 0 ? void 0 : _options$offset.x) - offset.x * ((_options$rotation = options.rotation) === null || _options$rotation === void 0 ? void 0 : _options$rotation.x) || 0;
        ry = ((_options$offset2 = options.offset) === null || _options$offset2 === void 0 ? void 0 : _options$offset2.y) - offset.y * ((_options$rotation2 = options.rotation) === null || _options$rotation2 === void 0 ? void 0 : _options$rotation2.y) || 0;
        rz = ((_options$offset3 = options.offset) === null || _options$offset3 === void 0 ? void 0 : _options$offset3.z) - offset.y * ((_options$rotation3 = options.rotation) === null || _options$rotation3 === void 0 ? void 0 : _options$rotation3.z) || 0;
      }
      const sizeMatch = cube.offsetHeight;
      data.current.fp = Math.min(1, cube.childNodes[0].getBoundingClientRect().width / sizeMatch);
      data.current.lp = Math.min(1, cube.childNodes[3].getBoundingClientRect().width / sizeMatch);
      data.current.tp = Math.min(1, cube.childNodes[4].getBoundingClientRect().height / (sizeMatch * 0.5));
      data.current.bp = Math.min(1, cube.childNodes[5].getBoundingClientRect().height / (sizeMatch * 0.75));
      cube.style.transform = "rotateX(".concat(rx, "deg) rotateY(").concat(ry, "deg) rotateZ(").concat(rz, "deg)");
      cube.childNodes[0].style.filter = "brightness(".concat(data.current.fp, ")");
      cube.childNodes[3].style.filter = "brightness(".concat(data.current.lp, ")");
      cube.childNodes[4].style.filter = "brightness(".concat(data.current.tp, ")");
      cube.childNodes[5].style.filter = "brightness(".concat(data.current.bp + 0.75, ")");
    }
    if (!shouldReduceMotion || timePassed < 600) {
      requestRef.current = requestAnimationFrame(update);
    }
  }, [speed, options, productId, shouldReduceMotion]);
  useEffect(() => {
    timeRef.current = Date.now();
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, [update]);
  if (!product) {
    return null;
  }
  return React.createElement("div", {
    className: clx('brand-cube', {
      ["brand-cube-".concat(product)]: product
    }, className),
    style: style
  }, React.createElement("div", {
    className: "brand-cube-faces",
    ref: cubeRef
  }, React.createElement("div", {
    className: "brand-cube-face brand-cube-front"
  }), React.createElement("div", {
    className: "brand-cube-face brand-cube-back"
  }), React.createElement("div", {
    className: "brand-cube-face brand-cube-right"
  }), React.createElement("div", {
    className: "brand-cube-face brand-cube-left"
  }), React.createElement("div", {
    className: "brand-cube-face brand-cube-top"
  }), React.createElement("div", {
    className: "brand-cube-face brand-cube-bottom"
  })));
};
setGlobal('svs.components.lbUi.brandCube.BrandCube', BrandCube);

 })(window);