(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/hero/assets/javascripts/components/hero-image.js') >= 0) return;  svs.modules.push('/components/lb-ui/hero/assets/javascripts/components/hero-image.js');

'use strict';

const HeroImage = _ref => {
  let {
    path
  } = _ref;
  return React.createElement("div", {
    className: "hero-image"
  }, path && React.createElement("img", {
    alt: "",
    src: path
  }));
};
setGlobal('svs.components.lbUi.hero.components.HeroImage', HeroImage);

 })(window);